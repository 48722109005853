<template>
  <Box>
    <Paper class="sticky top-[50.5px]" rounded="none" flex alignItems="center" justify="between">
      <Box flex alignItems="center">
        <Typography pr="2" variant="subtitle2" capitalize>
          {{ group.name }}
        </Typography>
        <Chip size="2xs" :label="itemsCount" />
      </Box>
      <Link v-if="itemsCount > 5" variant="default" color="primary" @click="toggleExpanded()">
        <Typography variant="caption1" capitalize>
          {{ expandText }}
        </Typography>
      </Link>
    </Paper>
    <Box flex direction="col">
      <Box v-for="item of limitedItems" :key="item.id" flex alignItems="center" spaceX="2" mt="2">
        <Chip variant="outlined" size="2xs" :label="item.id" />
        <Typography variant="caption1" truncateRow="two" color="inherit" capitalize>
          <Link :to="getLink(item)" class="link link-hover" @click="onClick()">
            {{ item.name }}
          </Link>
        </Typography>
      </Box>
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Box, Chip, Link, Paper, Typography } from '@lasso/luikit'
import { useAccessControl } from '@sidenav/hooks/useAccessControl'
import { useToggle } from '@vueuse/core'
import { SearchResultDTO } from '@sidenav/api'

const props = withDefaults(defineProps<{
  groupKey: string
  items: Array<SearchResultDTO>
}>(), {
  groupKey: '',
})

const emits = defineEmits<{
  close: []
}>()

const ENTITIES_HASH: Record<string, { type: string; name: string }> = {
  campaign: {
    type: 'campaign',
    name: 'Campaigns',
  },
  adgroup: {
    type: 'adgroup',
    name: 'AdGroups',
  },
  webevent: {
    type: 'Webevent',
    name: 'Digital Events',
  },
  audience: {
    type: 'audience',
    name: 'Audience',
  },
  location: {
    type: 'location',
    name: 'Locations',
  },
  brandsafety: {
    type: 'brandsafety',
    name: 'Brand Safety',
  },
  analytic: {
    type: 'analytic',
    name: 'Analytics',
  },
  creative: {
    type: 'creative',
    name: 'Creatives',
  },
}

const group = computed(() => ENTITIES_HASH[props.groupKey] || { type: 'unknown', name: 'Misc' })
const [isExpanded, toggleExpanded] = useToggle(false)
const expandText = computed(() => (isExpanded.value ? 'Collapse' : 'Expand'))
const itemsCount = computed(() => (props.items ? props.items?.length : 0))
const limitedItems = computed(() => (isExpanded.value ? props.items : props.items.slice(0, 5)))

const onClick = () => {
  emits('close')
}

const { features } = useAccessControl()

const getLink = (item: any) => {
  if ((group.value.type === 'campaign' || group.value.type === 'adgroup') && features.campaignMonitoringV2) {
    return item.linkNew || item.link
  }

  return item.link
}
</script>
